import { Link } from 'gatsby'
import { useUserData } from 'src/components/contexts/UserDataContext'
import { useCheckFavoriteProducts } from 'src/sdk/product/useWishlist'

import WishlistIcon from './WishlistIcon'

import './product-card-recommendation.scss'

type ReferenceProduct = {
  id: string
  name: string
  price: number
  oldPrice: number
  url: string
  images: {
    default: string
  }
  installment: {
    count: number
    price: string
  }
  available: string
}

type RecommendationProps = {
  product: ReferenceProduct
}

const ProductCardRecommendation = ({ product }: RecommendationProps) => {
  const { userData } = useUserData()

  const isInWishlist = useCheckFavoriteProducts(
    userData.UserId ?? 'undefineduser',
    product?.id
  )

  const formatPrice = (price: number) => {
    if (!price) {
      return { integer: 0, cents: 0 }
    }

    const [integer, cents] = String(price.toFixed(2)).split('.')

    return { integer, cents }
  }

  return (
    <div className="product-card-recommendation pb-3 px-2">
      <div className="product-card-recommendation__image-container relative">
        <WishlistIcon
          user={userData}
          fillWishlist={isInWishlist}
          prodId={product?.id}
        />
        <Link
          className="product-card-recommendation__image-container--image"
          to={`https:${product?.url}`}
        >
          <img
            src={`https:${product?.images.default}`}
            alt={product?.name}
            width={168}
            height={143}
            className="mx-auto"
          />
        </Link>
      </div>
      <div className="product-card-recommendation__name text-[10px] h-8 mt-4 mb-10">
        <Link to={`https:${product?.url}`}>{product?.name}</Link>
      </div>
      <div className="product-card-recommendation__price">
        <div className="product-card-recommendation__price--mainPrice font-bold py-2 px-1 bg-[#FFEA28] w-fit mb-1">
          <span className="text-xs align-super">R$</span>
          <span className="text-2xl">{`${
            formatPrice(product?.price)?.integer
          }`}</span>
          ,
          <span className="text-sm">{`${
            formatPrice(product?.price)?.cents
          }`}</span>
        </div>
        <div className="product-card-recommendation__price--installment text-[10px] h-[11px]">
          {product?.installment && product?.installment?.count > 1 && (
            <span>
              ou{' '}
              <span className="font-bold">{product?.installment?.count}</span>x
              de{' '}
              <span className="font-bold">{product?.installment?.price}</span>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductCardRecommendation
