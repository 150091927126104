import type { Variation } from 'src/components/product-page/Selector/types'
import './styles.scss'
import { SizeGuide } from 'src/components/restructure/product/sections/Sidebar/SkuSelector/SizeGuide/SizeGuide'

interface Props {
  variation: Variation
  onClickVariation: (name: any, value: string) => void
}

export function DefaultSelector({ variation, onClickVariation }: Props) {
  const variationName = variation.field.name

  const sortedVariationValues = [...variation.values].sort(
    (a, b) => a.position - b.position
  )

  const keywordsHiddenComponent = [
    'ÚNICO',
    'UNICO',
    'Único',
    'ÚNICA',
    'UNICA',
    'Única',
    'U',
    ',',
    '.',
    'B',
    'N/A',
    'UN',
    'variado',
    'Sem sabor',
    'SEM-SABOR',
    '3953',
    '3954',
  ]

  if (
    sortedVariationValues.length <= 1 &&
    keywordsHiddenComponent.includes(sortedVariationValues[0].name)
  ) {
    return null
  }

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <span className="text-deepGray font-bold font-roboto text-sm">
          {variationName}:
        </span>

        {variationName === 'Tamanho' && <SizeGuide />}
      </div>

      <ul
        className="flex items-center gap-[12px] flex-wrap mt-3"
        key={variation.field.id}
      >
        {sortedVariationValues.map((variationValue) => (
          <li key={variationValue.id}>
            <button
              disabled={!variationValue.isAvailable}
              className={`sku-selection-button relative text-sm font-roboto rounded-md px-[12px] py-2 border hover:border-[#007DBC] hover:text-[#007DBC] ${
                variationValue.selected
                  ? 'border-[#007DBC] text-[#007DBC]'
                  : 'border-gray text-darkGray'
              } ${
                variationValue.isAvailable
                  ? 'isAvailable'
                  : 'hover:border-gray cursor-not-allowed'
              }`}
              onClick={() =>
                onClickVariation(variationName, variationValue.name)
              }
            >
              <span> {variationValue.name} </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
