import { gql } from '@vtex/graphql-utils'
import { useLazyQuery } from 'src/sdk/graphql/useLazyQuery'
import type {
  GetRatingQuery as Query,
  QueryGetRatingArgs as Variables,
} from '@generated/graphql'

export const getRatingQuery = gql`
  query GetRating(
    $sort: String!
    $direction: String!
    $page: Int!
    $nb: Int!
    $site: Int!
    $locales: String!
    $keep_locales_order: Int!
    $productCode: Int!
  ) {
    getRating(
      direction: $direction
      keep_locales_order: $keep_locales_order
      locales: $locales
      nb: $nb
      page: $page
      productCode: $productCode
      site: $site
      sort: $sort
    ) {
      ...ReviewData_fragment
    }
  }
`

export function useGetRating() {
  return useLazyQuery<Query, Variables>(getRatingQuery, {} as Variables)
}
