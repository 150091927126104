import type { Flag } from 'src/components/product-page/context/types/product.type'

interface FlagsProps {
  flags: Flag[]
}

export function ProductFlag({ flags }: FlagsProps) {
  return (
    <>
      {flags.length ? (
        <div className="flags-wrapper">
          {flags.slice(0, 2).map((flag: Flag, index: number) => (
            <span
              key={`${index}`}
              className="flag"
              style={{
                background: `${flag.color}`,
                color: `${flag.textColor}`,
              }}
            >
              {flag.value}
            </span>
          ))}
        </div>
      ) : null}
    </>
  )
}
