import { stripUrl } from 'src/utils/stripUrl'

type InspirationalImageProps = {
  images: [
    {
      url: string
      label: string
    }
  ]
  title: string
}

export const InspirationalImage = ({
  inspirationalImage,
}: {
  inspirationalImage: InspirationalImageProps
}) => {
  return (
    <div className="flex items-center flex-col gap-16 vs:gap-10 vs:mb-10 ppp:mx-4 sm:mx-auto">
      {inspirationalImage?.images?.map((item, index) => {
        return (
          <div key={index}>
            <img
              src={stripUrl(item.url)}
              alt={item.label}
              loading="lazy"
              className="vs:h-[186px] rounded-[5px]"
              width={660}
              height={268}
            />
          </div>
        )
      })}
    </div>
  )
}
