import { Image } from 'src/components/ui/Image'
import { useEffect, useRef, useState } from 'react'
import { zoomIn, zoomOut, toggleZoom } from 'src/utils/zoomTools'
import { Modal } from 'src/components/common/Modal'
import Icon from 'src/components/ui/Icon'
import { useMobile } from 'src/hooks/useMobile'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import type { ProductItem } from 'src/components/product-page/context/types/product.type'
import type { Options } from '@splidejs/splide'

import './styles.scss'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'

type Props = {
  currentSku: ProductItem
}

function formatSrc(url: string, width: number, height: number) {
  const hasId = url.includes('/arquivos/ids/')

  if (!hasId || !width || !height) {
    return url
  }

  const [id] = url.split('/arquivos/ids/')[1].split('/')[0].split('-')

  const formattedId = `${id}-${width}-${height}`

  return url.replace(id, formattedId)
}

export function GridImage({ currentSku }: Props) {
  // new estruture
  const mainRef = useRef<Splide>(null)
  const thumbsRef = useRef<Splide>(null)
  const zoomModalRef = useRef<Splide>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [zoomed, setZoomed] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState(0)
  const { images } = currentSku

  const { screenHeight, screenWidth } = useMobile()

  useEffect(() => {
    if (mainRef?.current && thumbsRef.current && thumbsRef.current.splide) {
      mainRef.current.sync(thumbsRef.current.splide)
    }
  }, [])

  const mainOptions: Options = {
    type: 'loop',
    perPage: 1,
    perMove: 1,
    pagination: false,
    arrows: false,
    gap: '10px',
    heightRatio: 5,
    height: 628,
    breakpoints: {
      1024: {
        arrows: true,
      },
    },
  }

  const thumbsOptions: Options = {
    type: 'slide',
    rewind: false,
    gap: '12px',
    pagination: false,
    cover: false,
    focus: 0,
    direction: 'ttb',
    heightRatio: 7,
    perPage: 5,
    isNavigation: true,
    breakpoints: {
      1024: {
        heightRatio: 2,
        direction: 'ltr',
        fixedHeight: 56,
        arrows: false,
      },
    },
  }

  const handleOpenModal = (indexSlide: number) => {
    setIsOpen(true)
    setSelectedImage(indexSlide)
  }

  return (
    <>
      <div>
        <div className="flex h-fit md:h-[39rem] gap-x-6 gap-y-2 mt-4 md:mt-0 w-full flex-col-reverse md:flex-row">
          <div className="w-full md:w-[85px] h-full  flex items-center">
            <Splide
              options={thumbsOptions}
              ref={thumbsRef}
              className="thumbs-splide w-full md:w-auto"
              hasTrack={false}
            >
              <SplideTrack>
                {images?.map((image, idx) => (
                  <SplideSlide
                    key={image.imageUrl}
                    className="flex items-center justify-center"
                  >
                    <Thumb key={idx} image={image} idx={idx} />
                  </SplideSlide>
                ))}
              </SplideTrack>
            </Splide>
          </div>
          <div className="w-full md:w-[calc(100%-85px)] h-full">
            <Splide
              hasTrack={false}
              options={mainOptions}
              ref={mainRef}
              className="h-full main_splide"
            >
              <SplideTrack className="h-full">
                {images?.map((image, idx) => (
                  <SplideSlide key={image.imageUrl} className="!h-full">
                    <Image
                      className="h-full object-cover"
                      key={image.imageUrl}
                      src={image.imageUrl}
                      srcSet={formatSrc(image.imageUrl, 628, 628)}
                      width={628}
                      height={628}
                      alt={image.imageText}
                      loading={idx === 0 ? 'eager' : 'lazy'}
                      preload={idx === 0}
                      onMouseMove={(e) => zoomIn(e)}
                      onMouseLeave={(e) => zoomOut(e)}
                      onClick={() => handleOpenModal(idx)}
                    />
                  </SplideSlide>
                ))}
              </SplideTrack>
            </Splide>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        positionContent={screenWidth >= 1024 ? 'left-desktop' : 'left-mobile'}
        onClose={() => setIsOpen(false)}
      >
        <div className="p-8 w-[100vw] h-full flex flex-col gap-2">
          <button
            onClick={() => setIsOpen(false)}
            className="text-blue text-2xl ml-auto mr-0 flex"
          >
            <Icon name="Close" width={24} height={24} fill="#007cb9" />
          </button>
          <Splide
            options={{ perPage: 1, arrows: true, start: selectedImage }}
            ref={zoomModalRef}
          >
            {images?.map((image) => {
              return (
                <SplideSlide key={image.imageUrl}>
                  <div className="overflow-x-scroll overflow-y-scroll">
                    <Image
                      className="mx-auto"
                      alt={image.imageText}
                      src={image.imageUrl}
                      width={740}
                      height={screenHeight}
                      sizes="(max-width: 766px) 740px, 100vw"
                      onMouseMove={(e) => zoomIn(e)}
                      onMouseLeave={(e) => zoomOut(e)}
                      onClick={(e) => {
                        toggleZoom(e, zoomed)
                        setZoomed(!zoomed)
                      }}
                    />
                  </div>
                </SplideSlide>
              )
            })}
          </Splide>
        </div>
      </Modal>
    </>
  )
}

type ThumbProps = {
  image: {
    imageUrl: string
    imageText: string
  }
  idx: number
}

function Thumb({ image, idx }: ThumbProps) {
  return (
    <Image
      key={image.imageText}
      srcSet={formatSrc(image.imageUrl, 128, 128)}
      src={image.imageUrl}
      width={128}
      height={128}
      alt={image.imageText}
      style={{ objectFit: 'contain' }}
      loading={idx <= 4 ? 'eager' : 'lazy'}
      preload={idx <= 4}
      className="h-[56px] w-[56px] md:h-[85px] md:w-[85px]"
    />
  )
}
