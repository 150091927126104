import { formatterPrice } from 'src/utils/formatterPrice'
import type { ProductItem } from 'src/components/product-page/context/types/product.type'

import { Installment } from './Installment'
import { ProductSeller } from './ProductSeller'

type Props = {
  item: ProductItem
  productId: string
}

export function ProductPricing({ item, productId }: Props) {
  const currentPrice = item.offer.hasDiscount
    ? item.offer.price
    : item.offer.listPrice

  const discontPrice = item.offer.hasDiscount && item.offer.listPrice

  const currentPriceFormat = String(currentPrice).split('.')

  return (
    <div className="py-6">
      <div className="flex items-center gap-2">
        <p className="text-2xl text-neutral10 font-roboto font-medium">
          R$ {currentPriceFormat[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
          <sup className="text-xs ml-[2px]">
            {currentPriceFormat[1] ?? '00'}
          </sup>
        </p>

        {typeof discontPrice === 'number' && (
          <span className="text-sm text-darkGray font-roboto line-through">
            {formatterPrice(discontPrice)}
          </span>
        )}
      </div>

      <Installment currentSku={item} />

      <ProductSeller currentSku={item} productId={productId} />
    </div>
  )
}
