import './styles.scss'

export function ProductBenefits({
  productBenefits,
}: {
  productBenefits?: string
}) {
  return (
    <>
      {productBenefits && (
        <section className="produto-beneficios">
          <div className="section-title">
            <h3 className="section-title__text text-deepGray mb-11">
              Benefícios do Produto
            </h3>
          </div>
          <div
            className="product-details-content beneficios mb-11"
            dangerouslySetInnerHTML={{ __html: productBenefits }}
          />
        </section>
      )}
    </>
  )
}
