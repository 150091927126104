import { navigate } from 'gatsby'
import { useUserData } from 'src/components/contexts/UserDataContext'
import StarsReview from 'src/components/ui/StarsReview/StarsReview'
import {
  toggleWishList,
  useCheckFavoriteProducts,
} from 'src/sdk/product/useWishlist'
import type { Offer } from 'src/components/product-page/context/types/product.type'

import { loginUrl } from '../../../../store.config'
import { ProductFlag } from './ProductFlag'

import './styles.scss'

interface ProductTitleProp {
  title: string
  brandName: string
  refNumber?: string
  productBenefits?: string | null
  itemId: string
  seller: string
  offer: Offer
  flags?: any
  rating?: {
    total_ratings_average_note: number
    total_reviews: number
  } | null
}

function ProductTitle({
  title,
  brandName,
  refNumber,
  itemId,
  seller,
  flags,
  rating,
}: ProductTitleProp) {
  const { userData } = useUserData()

  const isInWishlist = useCheckFavoriteProducts(
    userData.UserId ?? 'undefineduser',
    itemId
  )

  return (
    <section className="mb-1 md:mb-0">
      <div className="flex gap-x-[12px] items-center pp:flex-row md: justify-between flex-col sm:flex-row md:justify-start ">
        <div className="relative">
          <p className="relative text-sm text-deepGray font-roboto">
            {brandName}
          </p>
        </div>
        <ProductFlag flags={flags} />
      </div>
      <div className="relative flex items-start justify-between gap-4 my-2">
        <h1 className="font-roboto max-w-[18rem] p:max-w-[20rem] md:max-w-[21rem] m-0 font-[500] text-xl text-neutral10 text-left">
          {title}
        </h1>

        <div className="absolute right-0 flex flex-col self-stretch md:self-auto md:gap-4 top-1">
          <span
            className={`favorite-button w-[20px] h-[20px] self-end my-auto ml-auto cursor-pointer ${
              isInWishlist ? 'favorite-button-fill' : ''
            }`}
            onClick={(e) =>
              userData.UserId
                ? toggleWishList({
                    id: userData.UserId,
                    itemId,
                    seller,
                    e,
                  })
                : navigate(loginUrl)
            }
            aria-hidden="true"
          />
          <p className="mt-2 text-xs whitespace-nowrap text-darkGray font-roboto">
            REF: {refNumber}
          </p>
        </div>
      </div>

      {typeof rating?.total_ratings_average_note === 'number' && (
        <div
          className="flex items-center gap-2"
          role="button"
          aria-hidden="true"
          onClick={() => {
            const sectionCustomerElement = document?.getElementById(
              'customer_reviews_section'
            )

            sectionCustomerElement?.scrollIntoView({
              block: 'start',
              inline: 'nearest',
            })
          }}
        >
          <StarsReview
            stars={rating?.total_ratings_average_note}
            totalRatings={rating.total_reviews}
            variant="black"
          />
        </div>
      )}
    </section>
  )
}

export default ProductTitle
