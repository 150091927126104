import type { ComponentsProps } from 'src/components/sections/ProductDetails/types/index'

import { InspirationalImage } from './InspirationalImage'
import { ProductWarranty } from './ProductWarranty'
import { ProductEcodesign } from './ProductEcodesign'
import { ProductHighlights } from './ProductHighlights'
import { VideoGallery } from './VideoGallery'
import { ProductDocuments } from './ProductDocuments'
import { SpecialistReviews } from './SpecialistReviews'

export const ChampionPdp = ({
  components,
}: {
  components: ComponentsProps
}) => {
  return (
    <div className="w-full my-5 mx-0 mb-10 bg-[#EFF1F3] max-w-[888px]">
      <div>
        {components?.promise?.title && (
          <h1 className="font-bold italic text-[40px] leading-10 text-blue uppercase w-full max-w-[660px] ppp:px-4 sm:px-0 mx-auto my-0 py-16 xl:text-center xs:text-left vs:pt-6 vs:pb-10">
            {components?.promise?.title}
          </h1>
        )}
        {components &&
          Object.keys(components?.inspirationalImage || {}).length !== 0 && (
            <InspirationalImage
              inspirationalImage={components?.inspirationalImage}
            />
          )}
        {components &&
          Object.keys(components?.productWarranty || {}).length !== 0 && (
            <ProductWarranty productWarranty={components?.productWarranty} />
          )}
        {components &&
          Object.keys(components?.productEcodesign || {}).length !== 0 && (
            <ProductEcodesign productEcodesign={components?.productEcodesign} />
          )}
        {components &&
          Object.keys(components?.productHighlights || {}).length !== 0 && (
            <ProductHighlights
              productHighlights={components?.productHighlights}
            />
          )}
        {components &&
          Object.keys(components?.videoGallery || {}).length !== 0 && (
            <VideoGallery videoGallery={components?.videoGallery} />
          )}
        {components &&
          Object.keys(components?.productDocuments || {}).length !== 0 && (
            <ProductDocuments productDocuments={components?.productDocuments} />
          )}
        {components &&
          Object.keys(components?.specialistReviews || {}).length !== 0 && (
            <SpecialistReviews
              specialistReviews={components?.specialistReviews}
            />
          )}
      </div>
    </div>
  )
}
