import Icon from 'src/components/ui/Icon'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from '@reach/router'
import './description.scss'

import { ProductBenefits } from '../ProductBenefits'

type Props = {
  technicalInformation?: string
  productDocuments?: string
  description: string
  productBenefits?: string
}

export function Description({
  technicalInformation,
  productBenefits,
  productDocuments,
  description,
}: Props) {
  const [isOpen, setIsOpen] = useState(true)
  const [showMoreStatus, setShowMoreStatus] = useState<boolean>(false)
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false)
  const productDescriptionElement = useRef<HTMLHeadingElement>(null)
  const { pathname } = useLocation()

  useEffect(() => {
    setIsOpen(true)
  }, [pathname])

  useEffect(() => {
    if (!productDescriptionElement.current) {
      return
    }

    const element = productDescriptionElement.current
    const { offsetHeight, scrollHeight } = element

    setIsButtonVisible(scrollHeight > offsetHeight)
  }, [productDescriptionElement, setIsButtonVisible])

  return (
    <article className="w-full">
      {description && (
        <>
          <h2 className="text-4xl text-deepGray font-bold uppercase text-center mb-8">
            Sobre o produto
          </h2>

          <p
            ref={productDescriptionElement}
            className={
              showMoreStatus
                ? 'text-lg text-neutral06 mb-4'
                : 'text-lg text-neutral06 max-lines text-clip overflow-hidden mb-4'
            }
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </>
      )}

      {isButtonVisible && (
        <button
          onClick={() => setShowMoreStatus(!showMoreStatus)}
          className="underline pointer mb-4 justify-center w-full align-middle text-bluePrimary"
        >
          {showMoreStatus ? 'Ver menos' : 'Ver mais'}
        </button>
      )}

      <ProductBenefits productBenefits={productBenefits} />

      {technicalInformation && (
        <section className="bg-neutral02 p-6 rounded">
          <header className="flex justify-between items-center">
            <h3 className="text-xl text-deepGray font-bold uppercase">
              Informações Técnicas
            </h3>
            <button
              className={`p-2 transition duration-300 ease-in-out ${
                !isOpen ? 'rotate-180 ' : ''
              }`}
              aria-label="Informações Técnicas"
              name="Informações Técnicas"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Icon name="CaretUp" width={24} height={24} />
            </button>
          </header>
          {isOpen && (
            <div
              className="product-description_specification"
              dangerouslySetInnerHTML={{
                __html: technicalInformation,
              }}
            />
          )}
        </section>
      )}

      {productDocuments && (
        <section className="bg-neutral02 p-6 rounded mt-6 productDocuments">
          <header className="header-description flex justify-between items-center mb-6">
            <h3 className="text-xl text-deepGray font-bold uppercase">
              Informações Técnicas
            </h3>
            <button
              className={`p-2 transition duration-300 ease-in-out ${
                !isOpen ? 'rotate-180 ' : ''
              }`}
              aria-label="Informações Técnicas"
              name="Informações Técnicas"
            >
              <Icon name="CaretUp" width={24} height={24} />
            </button>
          </header>
          <div
            className="px-6"
            dangerouslySetInnerHTML={{ __html: productDocuments }}
          />
        </section>
      )}
    </article>
  )
}
