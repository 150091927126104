/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'

type TableInstallmentProps = {
  installments?: {
    paymentOptions?: any
    paymentOptionsWithoutDecathlon?: any
  }
}
export function TableInstallment({ installments }: TableInstallmentProps) {
  return (
    <div className="absolute top-6 left-4 shadow-dropCard rounded-lg z-10 w-[160px]">
      <ul className="bg-neutral02 p-4 w-57 rounded">
        {installments?.paymentOptions.installments?.map(
          (item: any, index: number) => (
            <InstallmentItem
              key={index}
              item={item}
              paymentOptions={installments.paymentOptions}
              paymentOptionsWithoutDecathlon={
                installments.paymentOptionsWithoutDecathlon
              }
            />
          )
        )}
      </ul>
    </div>
  )
}

function InstallmentItem({
  item,
  paymentOptions,
  paymentOptionsWithoutDecathlon,
}: any) {
  const price = useFormattedPrice(item.value / 100)

  const countWithoutDecathlon =
    paymentOptionsWithoutDecathlon.installments[
      paymentOptionsWithoutDecathlon.installments.length - 1
    ].count

  return (
    <li key={item.count}>
      {item.count > 10 ? (
        <p className="hidden" />
      ) : paymentOptions.installments.length === 1 ? (
        <p>à vista por {price}</p>
      ) : (
        <p
          className={`py-2 text-darkGray font-roboto text-sm border-gray ${
            item.count !== 1 && 'border-t'
          }`}
        >
          {item.count}x de {price}{' '}
          {item.count > countWithoutDecathlon && (
            <span className="text-[#E32E00]">*cartão decathlon</span>
          )}
        </p>
      )}
    </li>
  )
}
