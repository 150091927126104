/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Image } from 'src/components/ui/Image'
import { formatterPrice } from 'src/utils/formatterPrice'
import { InformationIcon } from 'src/components/Icons/InformationIcon'
import { useProductLink } from 'src/sdk/product/useProductLinkV2'
import { Link } from 'gatsby'

import { SkuSelector } from './SkuSelector'
import type { Productkit } from './hook/KitLookContext'
import { Installment } from '../Installment'

type Props = {
  product: Productkit
  changeQnt(type: 'more' | 'less', productId: string): void
  toggleProductActive: (productId: string, skuId: string) => void
  selectProductSku: (productId: string, skuId: string) => void
  error?: string
  index?: number
}

export function CardItem({
  product,
  changeQnt,
  toggleProductActive,
  selectProductSku,
  error,
  index = 0,
}: Props) {
  const currentSku =
    product.items.find((item) => item.selected) ?? product.items[0]

  const currentSeller = currentSku.sellers.find((item) => item.sellerDefault)
  const currentPrice = currentSeller?.commertialOffer.Price ?? 0
  const lastPrice = currentSeller?.commertialOffer.ListPrice ?? 0
  const hasDiscont = currentPrice < lastPrice

  const productSlug = currentSku
    ? `${product.linkText}-${currentSku.itemId}`
    : `${product.linkText}-${product.items[0].itemId}`

  const linkProps = useProductLink({
    index,
    product,
    currentSku,
    slug: productSlug,
  })

  return (
    <div className="border border-gray rounded-lg p-4 pb-3">
      <div className="flex items-start justify-between gap-3 max-h-[88px]">
        <Link {...linkProps}>
          <Image
            src={currentSku.images[0].imageUrl}
            srcSet={currentSku.images[0].imageUrl}
            alt={currentSku.images[0].imageText}
            width={88}
            height={88}
            style={{ objectFit: 'contain' }}
            className="rounded-lg h-full"
          />
        </Link>
        <div className="h-[88px] flex flex-1 flex-col justify-between">
          <div className="w-full flex align-top justify-between gap-16">
            <Link {...linkProps}>
              <p className="text-deepGray font-roboto text-sm line-clamp-2">
                {product.productName}
              </p>
            </Link>

            <div className="flex items-center -mr-2 -mt-2 w-6 h-6">
              <label
                htmlFor={`product_select-${product.productId}`}
                className="text-sm font-medium text-gray-900 dark:text-gray-300 w-6 h-6"
              >
                <input
                  id={`product_select-${product.productId}`}
                  type="checkbox"
                  defaultChecked={currentSku?.selected}
                  onClick={() =>
                    toggleProductActive(product.productId, currentSku.itemId)
                  }
                  className="w-6 h-6 bg-gray-100 border-gray-300 focus:ring-backgroundPrimary"
                />
              </label>
            </div>
          </div>

          <div className="flex items-end justify-between font-roboto text-deepGray text-xs">
            <div className="flex items-center gap-3">
              <button
                onClick={() => changeQnt('less', product.productId)}
                disabled={(product.qnt ?? 1) <= 1}
                className="disabled:cursor-not-allowed"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 11H19V13H5V11Z"
                    fill={(product.qnt ?? 1) > 1 ? '#00689D' : '#8996A2'}
                  />
                </svg>
              </button>
              <p>{product.qnt ?? 1}</p>
              <button
                onClick={() => changeQnt('more', product.productId)}
                disabled={
                  (currentSeller?.commertialOffer.AvailableQuantity ?? 1) <=
                  (product.qnt ?? 1)
                }
                className="disabled:cursor-not-allowed"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                    fill={
                      (currentSeller?.commertialOffer.AvailableQuantity ?? 1) >
                      (product.qnt ?? 1)
                        ? '#00689D'
                        : '#8996A2'
                    }
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col items-end">
              <p className="font-roboto text-[15px] font-medium text-black flex gap-2 items-center">
                {hasDiscont && (
                  <span className="text-darkGray text-xs line-through ml-2">
                    {formatterPrice(lastPrice)}
                  </span>
                )}
                <strong>{formatterPrice(currentPrice)}</strong>
              </p>
              <Installment variant="card-kit" currentSku={currentSku} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-4 mt-4 pt-3 justify-between border-t border-gray">
        {currentSku && product && (
          <SkuSelector
            initialSku={currentSku}
            setCurrentSku={(skuSelect) =>
              selectProductSku(product.productId, skuSelect.itemId)
            }
            product={product}
            itemsSku={product.items}
            skuSpecifications={product.skuSpecifications}
          />
        )}
      </div>

      {error && (
        <div className="mt-2 flex items-start gap-1">
          <InformationIcon color="#E3262F" />
          <p className="font-roboto text-xs text-redDiscount">{error}</p>
        </div>
      )}
    </div>
  )
}
