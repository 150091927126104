/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSession } from '@faststore/sdk'
import { graphql, navigate } from 'gatsby'
import {
  BreadcrumbJsonLd,
  GatsbySeo,
  ProductJsonLd,
} from 'gatsby-plugin-next-seo'
import type { PageProps } from 'gatsby'
import { mark } from 'src/sdk/tests/mark'
import { gql } from '@vtex/graphql-utils'
import type { GetRatingQuery } from '@generated/graphql'
import { mapperBreadcrumbList, mapperOffers } from 'src/utils/mapperProducts'
import Page404 from 'src/pages/404'
import { discoverSkuBySlug } from 'src/utils/discoverSkuBySlug'
import axios from 'axios'
import type {
  Product,
  ProductItem,
} from 'src/components/product-page/context/types/product.type'
import { MasterDataLogger } from 'src/errors/logger'
import { ProductKitLook } from 'src/components/sections/ProductDetails/KitLook'
import { KitLookContextProvider } from 'src/components/sections/ProductDetails/KitLook/hook/KitLookContext'
import { ShippingProviderKitLook } from 'src/components/sections/ProductDetails/KitLook/hook/KitLookShippingContext'
import { formatCategoriesForEvent } from 'src/utils/formatted'
import { useEffect } from 'react'
import { sendEvent } from 'src/utils/restructure/analytics'
import { RecommendationProvider } from 'src/components/restructure/product/contexts/recommendations-context'
import GenericShelfv2 from 'src/components/restructure/product/shelves/GenericShelfV2'

import { channel } from '../../../store.config'

interface ProductItemKitLook extends ProductItem {
  kitItems: {
    itemId: string
    products: Product[]
  }
}

interface ProductKitLookData extends Product {
  items: ProductItemKitLook[]
}

type ServerData = {
  getRating: GetRatingQuery['getRating']
  getManufacturerCode: {
    manufacturerCode: string
  }
  stockKeepingUnit: {
    manufacturerCode: string
    productRefId: string
    refId: string
    reviewData: GetRatingQuery['getRating']
  }
  customData: Product
  productKitData: ProductKitLookData
  productUrlCanonical: string
}

export type Props = PageProps<any, any, unknown, ServerData> & { slug: string }

function Page(props: Props) {
  const { currency } = useSession()

  const { serverData } = props

  const productNotFound =
    !serverData ||
    !serverData?.customData ||
    !serverData?.customData?.items?.length

  const seoProducts = serverData?.productKitData?.items?.[0].kitItems.products

  useEffect(() => {
    if (productNotFound) {
      return
    }

    const productDetailEvent: any = {
      event: 'productDetail',
      ecommerce: {
        detail: {
          products: seoProducts.map((seoProduct: any) => {
            const [productItem] = seoProduct.items
            const offer = productItem.sellerDefault.sellerOffer

            return {
              brand: seoProduct.brand,
              category: seoProduct.sport,
              dimension1: seoProduct.productReference,
              dimension2: productItem.referenceId ?? '',
              dimension12: productItem.sellerDefault.sellerId,
              dimension13: productItem.sellerDefault.sellerName,
              dimension21: formatCategoriesForEvent(seoProduct.categories[0]),
              dimension22: productItem.itemId,
              dimension23: productItem.manufacturerCode,
              dimension24: 'página de produto',
              dimension25: 'kit look',
              id: seoProduct.productId,
              name: seoProduct.productName,
              price: offer.price,
              quantity: 1,
              variant: `${productItem.itemId} | ${productItem.name}`,
            }
          }),
        },
        eventCallback: () => {
          document.location = `productUrlCanonical`
        },
      },
    }

    sendEvent(productDetailEvent)
  }, [seoProducts, productNotFound])

  if (serverData === null || !serverData.productKitData) {
    return null
  }

  if (productNotFound) {
    navigate(`/404/?from=${encodeURIComponent(window.location.pathname)}`)

    return <Page404 />
  }

  const {
    productKitData,
    customData: product,
    productUrlCanonical,
  } = serverData

  const title = product.name ?? ''
  const description = product.description ?? ''

  const canonical = productUrlCanonical
  const breadcrumbList = mapperBreadcrumbList(product.categories?.reverse())
  const offers = mapperOffers(product)

  const [currentSku] = productKitData.items

  const { products } = currentSku.kitItems

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={description}
        canonical={`https://www.decathlon.com.br/${canonical}/p`}
        language="pt-br"
        openGraph={{
          type: 'og:product',
          url: canonical,
          title,
          description,
          images: currentSku.offerImages?.map((img: any) => ({
            url: img.url,
            alt: img.alternateName,
          })),
        }}
        metaTags={[
          {
            property: 'product:price:amount',
            content:
              currentSku.sellers
                .find((seller) => seller.sellerDefault)
                ?.commertialOffer.Price?.toString() ?? undefined,
          },
          {
            property: 'product:price:currency',
            content: currency.code,
          },
        ]}
      />

      <BreadcrumbJsonLd itemListElements={breadcrumbList.itemListElement} />

      <ProductJsonLd
        name={product.name}
        description={product.description}
        brand={product.brand}
        sku={currentSku.itemId}
        gtin={currentSku?.referenceId}
        images={currentSku.offerImages?.map((img) => img.url)}
        offersType="AggregateOffer"
        offers={offers}
      />

      <KitLookContextProvider
        products={products.map((item) => ({
          ...item,
          selected: true,
        }))}
        mainProduct={product}
      >
        <ShippingProviderKitLook>
          <ProductKitLook />
        </ShippingProviderKitLook>
      </KitLookContextProvider>

      <RecommendationProvider pageName="product">
        <GenericShelfv2 enableSession position="middle" />
        <GenericShelfv2 enableSession position="bottom" />
        <GenericShelfv2 enableSession position="area bonus" />
      </RecommendationProvider>
    </>
  )
}

export const querySSG = graphql`
  query ProductPageKitQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
    allCmsHome(
      filter: { sections: { elemMatch: { name: { eq: "Timer de ofertas" } } } }
    ) {
      nodes {
        id
        sections {
          name
          id
          data
        }
      }
    }
  }
`

export const querySSR = gql`
  query ServerProductPageKitQuery(
    $id: String!
    $channel: String!
    $direction: String!
    $keep_locales_order: Int!
    $locales: String!
    $nb: Int!
    $page: Int!
    $site: Int!
    $skuId: String!
    $sort: String!
  ) {
    customData(skuId: $skuId) {
      ...ProductDetailsFragment_CustomData
      collectionIds
      categories
      categoriesIds
    }

    stockKeepingUnit(
      direction: $direction
      keep_locales_order: $keep_locales_order
      locales: $locales
      nb: $nb
      page: $page
      site: $site
      skuId: $skuId
      sort: $sort
    ) {
      manufacturerCode
      productRefId
      refId
    }
  }
`

export const queryProductData = gql`
  query ProductDataKit($sku: String!) {
    customData(skuId: $sku) {
      ...ProductDetailsFragment_CustomData
      collectionIds
      categories
      categoriesIds
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
  query: Record<string, string>
}) => {
  const CACHE_CONTROL = `max-age=300, s-maxage=7200, stale-while-revalidate`

  try {
    const skuId = await discoverSkuBySlug(slug)

    const { execute } = await import('src/server')

    const { data } = await execute({
      operationName: querySSR,
      variables: {
        id: skuId,
        channel,
        direction: 'desc',
        keep_locales_order: 1,
        locales: 'pt_BR,pt_PT,es_ES,en,fr_FR,it_IT',
        nb: 3,
        page: 1,
        site: 1078,
        skuId,
        sort: 'createdAt',
      },
    })

    const productNotFound =
      !data || !data?.customData || !data?.customData?.items?.length

    if (productNotFound) {
      const params = new URLSearchParams({
        from: encodeURIComponent(`/${slug}/p`),
      })

      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': CACHE_CONTROL,
          location: `/404/?${params.toString()}`,
        },
      }
    }

    if (!data.customData.items[0].isKit) {
      const params = new URLSearchParams({
        from: encodeURIComponent(`/${slug}/kit`),
      })

      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': CACHE_CONTROL,
          location: `/404/?${params.toString()}`,
        },
      }
    }

    const { data: productKitData } = await axios.get(
      `https://decathlonstore.myvtex.com/v1/api/decathlon-pdp/product`,
      { params: { productId: data.customData.productId } }
    )

    const { data: productUrlCanonical } = await axios.get(
      `https://decathlonstore.myvtex.com/v1/api/decathlon-pdp/urlProduct?productId=${data.customData.productId}`
    )

    return {
      status: 200,
      props: {
        ...data,
        productKitData,
        productUrlCanonical,
      },
      headers: {
        'cache-control': CACHE_CONTROL,
      },
    }
  } catch (err) {
    console.warn('err =>', err)
    const logger = new MasterDataLogger()

    logger.logRequest({
      context: 'getServerData product page',
      slug,
      error: JSON.stringify(err),
    })

    return {
      status: 500,
      props: {},
      headers: {
        'cache-control': 'public, max-age=0, must-revalidate',
      },
    }
  }
}

Page.displayName = 'Page'
export default mark(Page)
