import { navigate } from 'gatsby'
import { useUserData } from 'src/components/contexts/UserDataContext'
import {
  toggleWishList,
  useCheckFavoriteProducts,
} from 'src/sdk/product/useWishlist'

import { loginUrl } from '../../../../../store.config'

type Props = {
  itemId: string
  sellerId: string
  title: string
  className?: string
}

export function ProductTitle({ itemId, sellerId, title, className }: Props) {
  const { userData } = useUserData()

  const isInWishlist = useCheckFavoriteProducts(
    userData.UserId ?? 'undefineduser',
    itemId
  )

  return (
    <div className={`flex items-center justify-between ${className}`}>
      <h1 className="font-roboto max-w-[18rem] p:max-w-[20rem] md:max-w-[21rem] m-0 font-[500] text-xl text-neutral10 text-left">
        {title}
      </h1>
      <span
        className={`favorite-button w-[20px] h-[20px] self-end my-auto ml-auto cursor-pointer ${
          isInWishlist ? 'favorite-button-fill' : ''
        }`}
        onClick={(e) =>
          userData.UserId
            ? toggleWishList({
                id: userData.UserId,
                itemId,
                seller: sellerId,
                e,
              })
            : navigate(loginUrl)
        }
        aria-hidden="true"
      />
    </div>
  )
}
