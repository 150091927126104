import Cookies from 'js-cookie'

import type { Productkit } from './hook/KitLookContext'

const newHandleEventIntegration = (products: Productkit[]) => {
  const newProductIds = products.map((product: Productkit) => product.productId)

  const kitLookCookieExists = !!Cookies.get('kitLook')

  const currentProductIds = kitLookCookieExists
    ? JSON.parse(Cookies.get('kitLook') as string)
    : []

  const combinedUniqueValues = [
    ...new Set([...currentProductIds, ...newProductIds]),
  ]

  if (kitLookCookieExists) {
    Cookies.remove('kitLook')
  }

  Cookies.set('kitLook', JSON.stringify(combinedUniqueValues), {
    path: '/',
    domain: '.decathlon.com.br',
  })
}

export default newHandleEventIntegration
