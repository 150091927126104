import { Image } from 'src/components/ui/Image'
import { stripUrl } from 'src/utils/stripUrl'

type SpecialistReviewsProps = {
  reviews: [
    {
      author: string
      imageUrl: string
      review: string
    }
  ]
  title: string
}

export const SpecialistReviews = ({
  specialistReviews,
}: {
  specialistReviews: SpecialistReviewsProps
}) => {
  return (
    <div className="flex flex-col items-center w-full max-w-[660px] mx-auto my-0 pt-10 vs:pt-0">
      <h1 className="text-[40px] leading-10 vs:text-2xl vs:leading-7 font-bold italic text-[#00689D] !my-6">
        {specialistReviews?.title}
      </h1>
      {specialistReviews?.reviews?.map((item, index) => {
        return (
          <div key={index}>
            <Image
              src={stripUrl(item.imageUrl)}
              alt="logo"
              loading="lazy"
              width={660}
              height={283}
              title="Indicação De Especialista"
            />
            <p className="text-2xl vs:text-xl font-bold py-4 leading-7 text-[#1A2A34]">
              {item.author}
            </p>
            <p className="text-base vs:text-lg font-normal leading-6 text-[#687787] mb-6">
              {item.review}
            </p>
          </div>
        )
      })}
    </div>
  )
}
