import { Suspense } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Modal } from 'src/components/common/Modal'
import Icon from 'src/components/ui/Icon/Icon'
import type { Options } from '@splidejs/react-splide'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'

import ProductCardRecommendation from '../ProductCardRecommendation/ProductCardRecommendation'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'

type Props = {
  setIsShelfLinxOpen: (value: boolean) => void
  sendEventDataLayer(eventAction: string, userEvent: boolean): void
  isOpen: boolean
  similarProducts: ReferenceProduct[]
  setModalShippingOpen: (value: boolean) => void
}

export function ModalRecommendations({
  setIsShelfLinxOpen,
  sendEventDataLayer,
  isOpen,
  similarProducts,
  setModalShippingOpen,
}: Props) {
  const options: Options = {
    type: 'slide',
    perPage: 3,
    perMove: 1,
    focus: 0,
    omitEnd: true,
    pagination: false,
    gap: '10px',
    breakpoints: {},
    fixedHeight: 315,
    classes: {
      arrows: 'splide__arrows',
    },
  }

  return (
    <Modal
      onClose={() => {
        setIsShelfLinxOpen(false)

        sendEventDataLayer('Interacao: Fechar', true)
      }}
      isOpen={isOpen}
      positionContent="custom"
      className="rounded-lg ppp:pl-4 ppp:pr-0 ppp:py-3 sm:p-8 ppp:w-[85%] ppp:left-[3%] ppp:top-[20%] pp:min-w-[345px] pp:top-[15%] pp:left-1/2 pp:-translate-x-1/2 sm:w-[90%] md:w-[976px] max-w-[976px] pp:max-h-[95vh] pp:min-h-fit"
    >
      <div className="modal-shelf-linx flex ppp:flex-col sm:flex-row ppp:gap-0 sm:gap-14">
        <div className="modal-shelf-linx__content flex flex-col max-w-[310px] justify-center ppp:mb-2 sm:mb-0">
          <div className="modal-shelf-linx__content--icon ppp:mb-1 sm:mb-[10px]">
            <Icon name="LocationIcon" width={18} height={22} />
          </div>
          <p className="modal-shelf-linx__content--text ppp:text-xs pp:text-sm sm:text-lg text-[#344450] uppercase sm:mb-3">
            infelizmente esse produto não está disponível para o seu cep
          </p>
          <p className="modal-shelf-linx__content--text ppp:text-[10px] pp:text-xs pp:w-full sm:text-base text-[#687787] sm:max-w-[234px] sm:mb-14">
            Selecionamos outros produtos similares que estão disponíveis.
          </p>
          <button
            className="modal-shelf-linx__content--button ppp:hidden sm:block sm:text-lg text-blue uppercase border border-blue py-2 w-[194px] max-w-[194px]"
            onClick={() => {
              setIsShelfLinxOpen(false)
              setModalShippingOpen(true)

              sendEventDataLayer('Interação: Alterar CEP', true)
            }}
          >
            alterar cep
          </button>
        </div>
        <div className="modal-shelf-linx__products ppp:mb-4 sm:mb-0">
          <h2 className="modal-shelf-linx__products--title ppp:text-xl sm:text-3xl text-[#4E5D6B] italic font-bold uppercase ppp:mb-2 sm:mb-4">
            nossa sugestão
          </h2>
          <div className="modal-shelf-linx__products--carousel max-w-[533px] xs:mx-auto sm:w-[392px] md:w-[533px]">
            <Suspense fallback={<Skeleton width={533} height={564} />}>
              <Splide
                hasTrack={false}
                options={options}
                id="recommendationLinxShipping"
              >
                <SplideTrack>
                  {similarProducts?.length &&
                    similarProducts.map((product) => {
                      return (
                        <SplideSlide
                          key={product?.name}
                          onClick={() =>
                            sendEventDataLayer('Interacao: Click Card', true)
                          }
                        >
                          <ProductCardRecommendation product={product} />
                        </SplideSlide>
                      )
                    })}
                </SplideTrack>

                <div className="splide__arrows">
                  <button className="splide__arrow splide__arrow--prev">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                    >
                      <path
                        d="M1.29128 0.710144C0.901279 1.10014 0.901279 1.73014 1.29128 2.12014L5.17128 6.00014L1.29128 9.88014C0.901279 10.2701 0.901279 10.9001 1.29128 11.2901C1.68128 11.6801 2.31128 11.6801 2.70128 11.2901L7.29128 6.70014C7.68128 6.31014 7.68128 5.68014 7.29128 5.29014L2.70128 0.700144C2.32128 0.320144 1.68128 0.320144 1.29128 0.710144Z"
                        fill="#001018"
                      />
                    </svg>
                  </button>
                  <button className="splide__arrow splide__arrow--next">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                    >
                      <path
                        d="M1.29128 0.710144C0.901279 1.10014 0.901279 1.73014 1.29128 2.12014L5.17128 6.00014L1.29128 9.88014C0.901279 10.2701 0.901279 10.9001 1.29128 11.2901C1.68128 11.6801 2.31128 11.6801 2.70128 11.2901L7.29128 6.70014C7.68128 6.31014 7.68128 5.68014 7.29128 5.29014L2.70128 0.700144C2.32128 0.320144 1.68128 0.320144 1.29128 0.710144Z"
                        fill="#001018"
                      />
                    </svg>
                  </button>
                </div>
              </Splide>
            </Suspense>
          </div>
        </div>
        <button
          className="modal-shelf-linx__content--button ppp:block sm:hidden ppp:text-sm sm:text-lg text-blue uppercase border border-blue py-2 w-[136px] max-w-[194px]"
          onClick={() => {
            setIsShelfLinxOpen(false)
            setModalShippingOpen(true)
          }}
        >
          alterar cep
        </button>
      </div>
    </Modal>
  )
}
