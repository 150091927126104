import { stripUrl } from 'src/utils/stripUrl'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import { useMobile } from 'src/hooks/useMobile'

import './styles.scss'

type ProductHighlightsProps = {
  images: [
    {
      url: string
      label: string
    }
  ]
  title?: string
}

export const ProductHighlights = ({
  productHighlights,
}: {
  productHighlights: ProductHighlightsProps
}) => {
  const { screenWidth } = useMobile()

  return (
    <div className="product-highlights_container">
      <Swiper
        navigation
        slidesPerView={2}
        spaceBetween={24}
        grabCursor
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
        }}
        modules={[Navigation, Pagination]}
      >
        {productHighlights?.images?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                src={stripUrl(item.url)}
                alt={item.label}
                loading="lazy"
                className="rounded-md"
                width={screenWidth >= 768 ? 318 : 340}
                height={screenWidth <= 767 ? 345 : 318}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
      {/* <Swiper
        className="product-highlights-swiper !max-w-[659px] w-full"
        navigation
        slidesPerView={2}
        // slidesPerGroupSkip={1}
        // centeredSlides={false}
        grabCursor
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
        }}
        modules={[Navigation, Pagination]}
      >
        {productHighlights?.images.map((item, index) => {
          const screenSize = window.screen.width

          return (
            <SwiperSlide key={index}>
              <img
                src={stripUrl(item.url)}
                alt={item.label}
                loading="lazy"
                className="rounded-md"
                width={screenSize >= 768 ? 318 : 340}
                height={screenSize <= 767 ? 345 : 318}
              />
            </SwiperSlide>
          )
        })}
      </Swiper> */}
    </div>
  )
}
