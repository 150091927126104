/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */

export const zoomIn = (e: any) => {
  const container: any = e.target
  const rect: any = container.getBoundingClientRect()

  const xPos = e.clientX - rect.left
  const yPos = e.clientY - rect.top

  const xPercent = `${xPos / (rect.width / 100)}%`
  const yPercent = `${yPos / (rect.height / 100)}%`

  Object.assign(container.style, {
    transform: 'scale(2.0)',
    transformOrigin: `${xPercent} ${yPercent}`,
  })
}

export const zoomOut = (e: any) => {
  const container: any = e.target

  Object.assign(container.style, {
    transform: 'scale(1.0)',
  })
}

export const toggleZoom = (e: any, zoomed: boolean) => {
  if (zoomed) {
    zoomOut(e)
  } else {
    zoomIn(e)
  }
}
