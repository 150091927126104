import { formattedMoney } from 'src/utils/formatted'

export function shippingDataFormat(shippingData: ShippingItem[]) {
  return shippingData?.map((shipping) =>
    shipping.logisticsInfo?.map((item) => ({
      itemItem: item.itemIndex,
      sla: item.slas?.map((sla) => ({
        seller: shipping?.items[0]?.seller,
        itemAvailability: shipping?.items[0]?.availability,
        id: sla?.id,
        name: sla?.deliveryIds ? sla.deliveryIds[0]?.courierName : sla?.name,
        price:
          sla?.price === 0
            ? 'Gratis'
            : formattedMoney(`${(sla?.price ?? 0) / 100}`, 'pt-BR', 'BRL'),
        shippingEstimate: sla?.shippingEstimate?.includes('bd')
          ? Number(sla?.shippingEstimate?.replace(/bd/g, ''))
          : sla?.shippingEstimate,
      })),
    }))
  )
}
