import type { ReactNode } from 'react'
import {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { shippingDataFormat } from 'src/components/product-page/context/utils/shippindDataFormat'
import type { ListShipping } from 'src/components/product-page/context/types/index.type'
import { getShippingData } from 'src/components/product-page/context/utils/getShippingData'

import { useKitLook } from './KitLookContext'

type ItemsShipping = {
  itemId: string
  productId: string
  quantity: string
  seller: string
}

type ShippingContextData = {
  listShipping: ListShipping[] | null
  getShipping: (
    postalCode: string,
    items: ItemsShipping[]
  ) => Promise<ShippingItem[]>
  clearShippings: () => void
  cep: string
  validating: boolean
  shippingData: ShippingItem[]
  cepIsValidForBuy: boolean
  setModalShippingOpen: (value: boolean) => void
  modalShippingOpen: boolean
  address?: {
    city: string
    state: string
    postalCode: string
  }
}

type ShippingProviderProps = {
  children: ReactNode
}

const ShippingContextKitLook = createContext<ShippingContextData>(
  {} as ShippingContextData
)

export function ShippingProviderKitLook({ children }: ShippingProviderProps) {
  const { products } = useKitLook()

  const [loading, setLoading] = useState(false)
  const [modalShippingOpen, setModalShippingOpen] = useState(false)
  const [shippingData, setShippingData] = useState<ShippingItem[]>([])
  const [cep, setCep] = useState('')

  useEffect(() => {
    setCep(localStorage.getItem('cep') ?? '')
  }, [])

  const getShipping = useCallback(
    async (postalCode: string, items: ItemsShipping[]) => {
      try {
        if (items.length < 1) {
          return []
        }

        setLoading(true)

        const shippings = await getShippingData({
          variables: {
            items,
            postalCode,
            country: 'BRA',
          },
          sellers: [products[0].items[0].sellers[0].sellerId],
        })

        setCep(shippings[0].postalCode)
        setShippingData(shippings)
        setLoading(false)

        return shippings
        // })
      } catch (e) {
        console.error(e)
        setLoading(false)

        return []
      }
    },
    [products]
  )

  const address = shippingData?.[0]?.AddressByPostalCode

  function clearShippings() {
    setShippingData([])
    setCep('')
    localStorage.removeItem('cep')
  }

  const listShipping = useMemo(() => {
    if (!shippingData) {
      return null
    }

    return shippingDataFormat(shippingData).map((item) => item[0].sla)
  }, [shippingData])

  const cepIsValidForBuy = useMemo(() => {
    if (shippingData) {
      return shippingData[0]?.items?.every(
        (item) => item.availability === 'available'
      )
    }

    return false
  }, [shippingData])

  return (
    <ShippingContextKitLook.Provider
      value={{
        listShipping,
        shippingData,
        getShipping,
        clearShippings,
        cep,
        validating: loading,
        cepIsValidForBuy,
        address,
        modalShippingOpen,
        setModalShippingOpen,
      }}
    >
      {children}
    </ShippingContextKitLook.Provider>
  )
}

export const useShippingKitLook = () => useContext(ShippingContextKitLook)
