import { Image } from 'src/components/ui/Image'
import { stripUrl } from 'src/utils/stripUrl'
import { useMobile } from 'src/hooks/useMobile'

type ProductWarrantyProps = {
  description: string
  logo: string
  title: string
}

export const ProductWarranty = ({
  productWarranty,
}: {
  productWarranty: ProductWarrantyProps
}) => {
  const { screenWidth } = useMobile()

  return (
    <div className="product-warranty-section max-w-[660px] bg-[#1B1B1A] rounded-[0.475rem] ppp:px-4 sm:px-6 ppp:py-5 sm:py-11 ppp:mx-4 sm:mx-auto ppp:my-10 sm:my-16 flex gap-4">
      <div
        className={`product-warranty-section__logo ${
          screenWidth < 768 ? 'hidden' : 'block'
        } flex-[0_0_92px] items-stretch flex flex-col justify-center`}
      >
        <Image
          className="my-auto"
          src={stripUrl(productWarranty?.logo)}
          alt="logo"
          loading="lazy"
          width={92}
          height={92}
        />
      </div>
      <div className="product-warranty-section__content">
        <h1 className="product-warranty-section__content--title font-bold sm:text-[28px] ppp:text-sm leading-7 text-white uppercase italic ppp:mb-4 sm:mb-8 ppp:flex ppp:gap-2 sm:block ppp:items-center">
          <Image
            className={`my-auto ${screenWidth < 768 ? 'block' : 'hidden'}`}
            src={stripUrl(productWarranty?.logo)}
            alt="logo"
            loading="lazy"
            width={48}
            height={48}
          />
          {productWarranty?.title}
        </h1>
        <p className="product-warranty-section__content--text ppp:text-sm sm:text-base font-normal leading-6 text-white">
          {productWarranty?.description}
        </p>
      </div>
    </div>
  )
}
