import { useEffect, useState, useMemo } from 'react'
import { GridImage } from 'src/components/product/GridImage'
import { Description } from 'src/components/product/Description'
import axios from 'axios'
import { ChampionPdp } from 'src/components/product/ChampionPdp'
import { isEmpty } from 'ramda'
import { formatterPrice } from 'src/utils/formatterPrice'
import { InformationIcon } from 'src/components/Icons/InformationIcon'
import type { ItemListElement } from 'src/components/product-page/types'
import Breadcrumb from 'src/components/ui/Breadcrumb/Breadcrumb'
import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { RecommendationProvider } from 'src/components/restructure/product/contexts/recommendations-context'
import GenericShelfv2 from 'src/components/restructure/product/shelves/GenericShelfV2'

import { SelectShippingKitLook } from './SelectShipping'
import type { ChampionProps } from '../types/index'
import { api } from '../../../../../store.config'
import { CardItem } from './Carditem'
import { useKitLook } from './hook/KitLookContext'
import { ProductTitle } from './ProductTitle'
import { useShippingKitLook } from './hook/KitLookShippingContext'
import handleEventIntegration from './handleEventIntegration'
import newHandleEventIntegration from './newHandleEventIntegration'

import '../styles.scss'

export function ProductKitLook() {
  const {
    mainProduct,
    products,
    changeQntProduct,
    toggleProductActive,
    selectProductSku,
    resume,
    addToCard,
    setNotification,
    notification,
  } = useKitLook()

  const { cepIsValidForBuy, setModalShippingOpen, cep } = useShippingKitLook()
  const { orderForm } = useCheckout()

  const currentSku = mainProduct?.items?.[0]

  const currentSeller =
    currentSku.sellers.find((seller) => seller.sellerDefault) ??
    currentSku.sellers[0]

  const [champion, setChampion] = useState<ChampionProps[]>([])

  useEffect(() => {
    axios
      .get(
        `https://${api.storeId}.myvtex.com/api/dataentities/product/search?_schema=pdpcampea&_where=(productId=${mainProduct.productId})`
      )
      .then((response) => {
        const { data } = response

        setChampion(data)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }, [mainProduct])

  const productsSelectShipping = useMemo(() => {
    const productsActive = products
      .filter((item) => item.selected)
      .map((item) => {
        const itemActive =
          item.items.find((sku) => sku.selected) ?? item.items[0]

        return {
          ...item,
          itemActive,
        }
      })

    return productsActive.map((item) => ({
      productId: item.productId,
      itemId: item.itemActive?.itemId,
      quantity: String(item.qnt ?? 1),
      seller: item.itemActive.sellers[0].sellerId,
    }))
  }, [products])

  function handelAddToCard() {
    if (productsSelectShipping?.length < 1) {
      setNotification([
        {
          location: 'frete',
          message:
            'Você precisa selecionar pelo menos um produto para adicionar ao carrinho.',
        },
      ])

      return
    }

    if (!cep) {
      setModalShippingOpen(true)

      return
    }

    if (!cepIsValidForBuy) {
      return
    }

    newHandleEventIntegration(products)

    handleEventIntegration(products, orderForm.orderFormId)

    addToCard(products)
  }

  const breadcrumbList: ItemListElement[] = [...(mainProduct.categories ?? [])]
    .sort((a, b) => a.length - b.length)
    .map((path: string, index: number) => {
      return {
        item: path,
        name: path.slice(1).slice(0, -1).split('/').pop()!,
        position: index + 1,
      }
    })

  const msgErrorFrete = notification?.find((item) => item.location === 'frete')

  return (
    <div className="max-w-screen-xl w-full mx-auto ppp:px-0 pp:px-6">
      <Breadcrumb breadcrumbList={breadcrumbList} />

      <section className="mt-8">
        <ProductTitle
          itemId={currentSku.itemId}
          sellerId={currentSeller.sellerId}
          title={mainProduct.name}
          className="md:hidden"
        />
        <section className="flex flex-col md:flex-row gap-x-6">
          <section className="w-full md:w-[60%] lg:w-[65%]">
            <GridImage currentSku={currentSku} />

            <section className="hidden gap-x-6 mt-16 md:block">
              <RecommendationProvider pageName="product">
                <GenericShelfv2 enableSession position="top" />
              </RecommendationProvider>
              <Description
                description={mainProduct.description}
                productDocuments={mainProduct.productDocuments}
                technicalInformation={mainProduct.technicalInformation}
                productBenefits={mainProduct.productBenefits}
              />
            </section>
          </section>

          <section className="flex-1 w-full md:w-[35%]">
            <ProductTitle
              itemId={currentSku.itemId}
              sellerId={currentSeller.sellerId}
              title={mainProduct.name}
              className="hidden md:flex"
            />
            <ul className="mt-6 flex flex-col gap-4">
              {products.map((item) => (
                <li key={item.productId}>
                  <CardItem
                    product={item}
                    changeQnt={changeQntProduct}
                    toggleProductActive={toggleProductActive}
                    selectProductSku={selectProductSku}
                    error={
                      notification?.find(
                        (noti) => item.productId === noti.productId
                      )?.message
                    }
                  />
                </li>
              ))}
            </ul>

            <div className="mt-6">
              <p className="font-roboto text-sm font-bold text-deepGray">
                Total
              </p>
              <p className="flex items-center justify-between mt-2">
                <span className="font-roboto text-deepGray">
                  {resume.qnt ?? 0} produtos selecionados
                </span>
                <strong className="font-roboto text-xl font-bold">
                  {formatterPrice(resume.totalPrice ?? 0)}
                </strong>
              </p>

              <button
                onClick={() => handelAddToCard()}
                className="mt-4 flex items-center justify-center bg-backgroundPrimary text-white py-3.5 w-full rounded font-roboto font-bold"
              >
                Adicionar ao carrinho
              </button>
              {msgErrorFrete && (
                <div className="mt-2 flex items-start gap-1">
                  <InformationIcon color="#EE000C" />
                  <p className="text-redDiscount font-roboto text-xs">
                    {msgErrorFrete.message}
                  </p>
                </div>
              )}
            </div>

            <SelectShippingKitLook
              products={productsSelectShipping}
              sellerName={currentSeller.sellerName}
            />
          </section>
        </section>

        <section className="flex gap-x-6 mt-16 md:hidden">
          <section className="w-full md:w-[60%]">
            <RecommendationProvider pageName="product">
              <GenericShelfv2 enableSession position="top" />
            </RecommendationProvider>
            <Description
              description={mainProduct.description}
              productDocuments={mainProduct.productDocuments}
              technicalInformation={mainProduct.technicalInformation}
              productBenefits={mainProduct.productBenefits}
            />
          </section>
        </section>

        <div
          className={`${
            isEmpty(champion[0]?.components?.promise) ? 'hidden' : 'flex'
          }`}
        >
          <ChampionPdp components={champion[0]?.components} />
        </div>
      </section>
    </div>
  )
}
