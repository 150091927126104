import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import { useMobile } from 'src/hooks/useMobile'

type VideoGalleryProps = {
  title: string
  videos: [
    {
      url: string
      label: string
      duration: string
    }
  ]
}

export const VideoGallery = ({
  videoGallery,
}: {
  videoGallery: VideoGalleryProps
}) => {
  const { screenWidth } = useMobile()

  return (
    <div className="videoGallery_container">
      <Swiper
        className="videoGallery-swiper"
        navigation
        slidesPerView={3}
        spaceBetween={24}
        grabCursor
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        }}
        modules={[Navigation, Pagination]}
      >
        {videoGallery?.videos.map((item, index) => {
          const newUrl = item.url.replace('youtu.be', 'youtube.com/embed')

          return (
            <SwiperSlide key={index}>
              <iframe
                className="rounded-md"
                width={screenWidth >= 768 ? '204' : '310'}
                height={screenWidth >= 768 ? '149' : '186'}
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture brightcove"
                title="youtube video/brightcove video"
                src={newUrl}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}
