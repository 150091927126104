import { useState } from 'react'
import {
  ArrowIconUp,
  ArrowIconDown,
} from 'src/components/ui/SidebarService/SidebarServiceArrow'
import Button from 'src/components/ui/Button'
import { Image } from 'src/components/ui/Image'
import { stripUrl } from 'src/utils/stripUrl'
import './styles.scss'

type ProductDocumentsProps = {
  documents: [
    {
      documentName: string
      documentUrl: string
      iconUrl: string
    }
  ]
  title: string
}

export const ProductDocuments = ({
  productDocuments,
}: {
  productDocuments: ProductDocumentsProps
}) => {
  const [openContent, setOpenContent] = useState<boolean>(false)
  const [icon, setIcon] = useState<boolean>(false)

  const handleDropDown = () => {
    setOpenContent(!openContent)
    setIcon(!icon)
  }

  return (
    <div className="mt-16 vs:mt-0 ppp:mb-10">
      <div className="flex items-center justify-center font-roboto">
        <Button
          className="button-dropdown w-full max-w-[660px] bg-neutral02 rounded !px-6 !py-6"
          aria-label="Open or close dropdown"
          onClick={handleDropDown}
        >
          <div className="flex items-center justify-between">
            <h1 className="font-bold text-lg leading-6 text-[#4E5D6B] uppercase my-0 text-center">
              {productDocuments?.title}
            </h1>
            {icon ? <ArrowIconUp /> : <ArrowIconDown />}
          </div>
          {openContent && (
            <div className="flex items-center justify-start gap-5 flex-wrap pt-4 pl-[90px] pr-[90px] vs:pl-0 vs:pr-0">
              {productDocuments?.documents?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="max-w-[204px] w-full vs:max-w-full"
                  >
                    <a
                      href={item?.documentUrl}
                      className="flex items-center border-[1px] border-solid border-gray rounded-[10px]"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="product-documents_ico ml-2"
                        src={stripUrl(item?.iconUrl)}
                        alt="logo"
                        loading="lazy"
                        width={24}
                        height={24}
                        title={item?.documentName}
                      />
                      <p className="text-sm font-normal leading-[17px] text-[#333333] pl-2 py-[11px] pr-[22px]">
                        {item?.documentName}
                      </p>
                    </a>
                  </div>
                )
              })}
            </div>
          )}
        </Button>
      </div>
    </div>
  )
}
