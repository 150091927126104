import type { Productkit } from './hook/KitLookContext'

const handleEventIntegration = async (
  products: Productkit[],
  orderFormId: string
) => {
  const orderFormRequest = await fetch('/api/getOrderFormById', {
    method: 'POST',
    body: JSON.stringify({
      orderFormId,
    }),
    headers: {
      Accept: 'application/vnd.vtex.ds.v10+json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })

  const orderFormResponse: any = await orderFormRequest.json()

  const customApps = orderFormResponse.response?.customData?.customApps

  const kitLookCustomApp = customApps.find((customApp: any) => {
    return customApp.id === 'kitlook'
  })

  const newProductIds = products.map((product: Productkit) => product.productId)
  const currentProductIds = kitLookCustomApp?.fields?.products
    ? JSON.parse(kitLookCustomApp.fields.products)
    : []

  const combinedUniqueValues = [
    ...new Set([...currentProductIds, ...newProductIds]),
  ]

  const updateDataRequest = await fetch('/api/changeCustomDataField', {
    method: 'POST',
    body: JSON.stringify({
      orderFormId,
      appId: 'kitlook',
      appFieldName: 'products',
      appFieldValue: JSON.stringify(combinedUniqueValues),
    }),
    headers: {
      Accept: 'application/vnd.vtex.ds.v10+json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })

  return updateDataRequest.json()
}

export default handleEventIntegration
